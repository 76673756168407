<template>
  <div class="overdue">
    <img src="~assets/images/auth/auth-top-bg.png" class="auth-top-bg" alt="" />
    <img src="~assets/images/auth/auth-bot-bg.png" class="auth-bot-bg" alt="" />
    <div class="box">
      <div class="top">提示</div>
      <div class="content">
        <img src="~assets/images/auth/change_Password.png" alt="" />
        <p class="yellow">您已超过{{ days }}天未修改密码了</p>
        <p class="msg">为了您的账号安全，请修改密码后再登录！</p>
        <div class="btn" @click="$router.push('/expiredpassword')">立即修改</div>
      </div>
    </div>
    <div class="bottom-hint-box align-center">
      {{ $static.footer_text }}
      <a href="https://beian.miit.gov.cn" target="_blank" class="records-link">{{ $static.record_no }}</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      days: 0,
    };
  },
  created() {
    this.days = localStorage.getItem('day');
  },
  mounted() {},
  methods: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.overdue {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  overflow-x: auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background-color: #f6f8f9;
  .auth-top-bg {
    width: 910px;
    height: 520px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .auth-bot-bg {
    width: 100%;
    height: 290px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
  .box {
    width: 850px;
    height: 814px;
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 19px;

    .top {
      width: 100%;
      height: 64px;
      border-bottom: 1px solid #ebeff2;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #1c1f21;
      display: flex;
      align-items: center;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .yellow {
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffa509;
        margin-top: 31px;
      }
      .msg {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1c1f21;
        margin-top: 20px;
      }
      .btn {
        width: 140px;
        height: 42px;
        border: 1px solid #b7bbbf;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #93999f;
        margin-top: 40px;
      }
      .btn:hover {
        border: 1px solid #93999f;
        color: #71777d;
        cursor: pointer;
      }
    }
  }
  .bottom-hint-box {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #93999f;
    line-height: 20px;
    position: absolute;
    left: 0;
    bottom: 20px;
    z-index: 1;
    a {
      margin-left: 6px;
      color: #93999f;
      text-decoration: none;
    }
  }
}
</style>
